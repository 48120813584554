<!--
 * @Description: 登录用户的token
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-17 11:12:42
-->
<template>
    <el-card class="box query">
      <el-form :model="query" ref="query" class="no-mg-b" :inline="true">
        <el-form-item label="关键字">
          <el-input v-model="query.q" placeholder="姓名、手机号" style="width:300px;" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="box data" v-loading="loading">
      <el-table :data="tableData.data" border stripe class="fixed">
        <el-table-column label="应用" width="150" prop="appName"></el-table-column>
        <el-table-column label="公司名称" prop="companyName" min-width="320"></el-table-column>
        <el-table-column label="姓名" width="120" prop="name"></el-table-column>
        <el-table-column label="手机" width="150" prop="mobile"></el-table-column>
        <el-table-column label="Token值" width="300" prop="token"></el-table-column>
        <el-table-column label="过期时间" width="180" prop="expires"></el-table-column>
        <el-table-column label="IP地址" width="150" prop="ipAddress"></el-table-column>
        <el-table-column label="创建时间" width="180" prop="createAt"></el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template #default="scope">
            <el-popconfirm
              width="220"
              v-if="is_can_del"
              title="您确定要删除此数据？"
              @confirm="onDelete(scope.row)"
            >
              <template #reference>
                <el-button
                  size="small"
                  type="danger"
                  link
                  icon="delete"
                  :loading="scope.row.deleting"
                >删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :current-page="query.pageIndex"
        :page-sizes="[20, 40, 60, 80, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="tableData.counts"
      ></el-pagination>
    </el-card>
  </template>
    
    <script>
  export default {
    data() {
      return {
        loading: false,
        query: {
          pageIndex: 1,
          pageSize: 20,
          order: {
            sort: "",
            fieldName: "",
          },
          q: "",
        },
        tableData: {
          counts: 0,
        },
        current_item: null,
        is_can_del: false,
      };
    },
    created() {
      this.is_can_del = this.$power("admin/v1/consumerToken/del");
      this.loadData();
    },
    methods: {
      /**
       * 加载数据
       */
      loadData() {
        this.loading = true;
        //审核中
        this.query.status = 3;
        this.$http
          .post("/admin/v1/consumerToken", this.query)
          .then((res) => {
            if (res.code == 0) {
              this.tableData = res.data;
            }
          })
          .finally(() => (this.loading = false));
      },
      /**
       * 搜索
       */
      onSearch() {
        this.query.pageIndex = 1;
        this.loadData();
      },
      /**
       * 排序
       */
      onSort(e) {
        if (e.prop) {
          this.query.order.sort = e.order;
          this.query.order.fieldName = e.prop;
          this.loadData();
        }
      },
  
      /**
       * 分页页数更改
       */
      pageSizeChange(val) {
        this.query.pageSize = val;
        this.loadData();
      },
  
      /**
       * 分页页码更改
       */
      pageCurrentChange(val) {
        this.query.pageIndex = val;
        this.loadData();
      },
  
      /**
       * 删除
       */
      onDelete(item) {
        item.deleting = true;
        this.$http
          .get(`admin/v1/consumerToken/del?id=${item.id}`)
          .then((res) => {
            if (res.code == 0) {
              if (this.query.pageIndex > 1) {
                if (this.tableData.data.length <= 1) {
                  this.query.pageIndex--;
                }
              }
              this.loadData();
            }
          });
      },
    },
  };
  </script>
    
    <style lang="scss" scoped>
  </style>